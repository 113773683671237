@import "../../styles/_colors";

.navBar {
  //background-color: transparent;
  //background-color: $primary-color-fadded;
  background: linear-gradient($primary-color 10%, transparent 100%);
  box-sizing: border-box;
  color: $light-gray;
  font-size: 18px;
  padding-bottom: 30px;
  padding-top: 30px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.body{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  text-shadow: 0px 0px 5px $black;

  .name {
    color: $white-fadded-2;
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 0.25em;
    margin-right: 25px;
    .pageOpen {
      color: $white;
    }
    a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
    &:hover {
      color: $white;
    }
  }

  .menu {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1em;
    margin-right: 1em;
    vertical-align: bottom;
    .pageOpen {
      color: $white;
    }
    a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
    span {
      color: $white-fadded-2;
      cursor: pointer;
      margin-right: 30px;
      white-space: nowrap;
      &:hover {
        color: $white;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.languageButton {
  background-color: $white;
  border-radius: 4px;
  border: none;
  color: $purple-blue;
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
  max-height: 2em;
  min-width: 65px;
  padding-left: 0.9em;
  padding-right: 0.9em;
  &:hover {
    background-color: $white-fadded-1;
  }
}
