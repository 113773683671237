@import "../../styles/_colors";

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.pageContent {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: justify;

  .title {
    display: block;
    font-size: 32px;
    margin-top: 1em;
  }

  .memberCard {
    background-color: $primary-color-fadded;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    margin-bottom: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 2em;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .name {
      align-self: baseline;
      font-size: 24px;
      margin-bottom: 0.5em;
      margin-right: 1em;
    }

    .role {
      align-self: baseline;
      font-size: 18px;
      margin-bottom: 0.5em;
    }

    .image {
      align-self: center;
      border-radius: 5px;
      flex: 0 0;
      margin-top: 1em;
      margin-right: 1em;
      min-width: 250px;
      width: 250px;
    }

    .imageTaoDing {
      background: url("../../images/tao-ding.jpg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      height: 250px;
      min-height: 250px;
      @media (max-width: 767px) {
        margin-bottom: 2em;
      }
    }

    .imageDianaDing {
      background: url("../../images/diana-ding.jpg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      height: 250px;
      min-height: 250px;
      @media (max-width: 767px) {
        margin-bottom: 2em;
      }
    }

    .description {
      align-self: center;
      flex: 1 1;
      margin-left: 1em;
      margin-top: 0;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

}
