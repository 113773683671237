@import "../../styles/_colors";

.pageContent {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: justify;

  .title {
    display: block;
    font-size: 32px;
    padding-bottom: 1em;
  }

  .videoGroup {
    background-color: $primary-color-fadded;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    margin-bottom: 2.5em;
    padding: 1em;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}

.videoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 150px;
  overflow: hidden;
  margin: 1em;
  position: relative;
  @media (max-width: 767px) {
    height: 240px;
  }
}

.videoContainer iframe {
  border-radius: 10px;
  border-width: 0px;
  flex: 1 1 auto;
}

.singleVideo {
  height: 400px;
  max-height: 80vh;
  @media (max-width: 767px) {
    height: 350px;
  }
}
